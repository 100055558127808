body {
  font-family: 'Poppins';
}

.pbo-gray-card {
  background-color: #dfdfdf;
  border-radius: 2rem 2rem 0rem 2rem;
  border: none;
  padding: 1rem;
}

.pbo-gray-card hr {
  color: #D90429;
}

.pbo-dark-card {
  background-color: #212529;
  border-radius: 2rem 2rem 0rem 2rem;
  border: none;
  box-shadow: .75rem 0 #D90429;
  color: #fff;
  padding: 1rem;
}

.pbo-dark-card hr {
  color: #D90429;
}

.pbo-title-img-1 {
  border-radius: 0rem 2rem 2rem 2rem;
  box-shadow: .75rem 0 #D90429;
}

.pbo-title-img-2 {
  margin-bottom: 1rem;
  border-radius: 2rem 0rem 2rem 2rem;
  box-shadow: .75rem 0 #D90429;
}

.pbo-title-img-3 {
  border-radius: 2rem 2rem 0rem 2rem;
  box-shadow: .75rem 0 #D90429;
}

.pbo-icons {
  display: inline-block;
  background-color: #212529;
  padding: 1.4rem;
  border-radius: 1rem 1rem 0rem 1rem;
  box-shadow: 0.5rem 0 #D90429;
}

.pbo-icons img {
  width: 2.5rem;
  height: 2.5rem;
}

.btn-pbo {
  color: #fafafa;
  border-radius: 0rem 1rem 1rem 1rem;
  background-color: #D90429;
  border-color: #D90429;
  padding: .5rem 2rem .5rem 2rem;
}

.btn-pbo:hover,
.btn-pbo:focus,
.btn-pbo:active,
.btn-pbo.active,
.open .dropdown-toggle.btn-pbo {
  color: #fafafa;
  background-color: #CC0000;
  border-color: #CC0000;
}

.btn-pbo:active,
.btn-pbo.active,
.open .dropdown-toggle.btn-pbo {
  background-image: none;
}

.btn-pbo.disabled,
.btn-pbo[disabled],
fieldset[disabled] .btn-pbo,
.btn-pbo.disabled:hover,
.btn-pbo[disabled]:hover,
fieldset[disabled] .btn-pbo:hover,
.btn-pbo.disabled:focus,
.btn-pbo[disabled]:focus,
fieldset[disabled] .btn-pbo:focus,
.btn-pbo.disabled:active,
.btn-pbo[disabled]:active,
fieldset[disabled] .btn-pbo:active,
.btn-pbo.disabled.active,
.btn-pbo[disabled].active,
fieldset[disabled] .btn-pbo.active {
  background-color: #D90429;
  border-color: #D90429;
}

.btn-pbo .badge {
  color: #D90429;
  background-color: #fafafa;
}

.pbo-link {
  text-decoration: none;
  font-weight: bold;
  color: #D90429;
  opacity: 0.7;
}

.pbo-link:hover {
  text-decoration: none;
  font-weight: bold;
  color: #D90429;
  opacity: 1.0;
}

.factoryNavbarLogo {
  height: 56px;
}