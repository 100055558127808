@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-trailer {
  position: absolute !important;
  object-fit: cover;
  z-index: -1;
  right: 0;
  width: 65% !important;
}

[data-bs-theme=dark] .bg-dark-transparent-gradient {
  background: linear-gradient(90deg, #212529 0%, #212529 45%, #21252900 100%);
}

[data-bs-theme=light] .bg-dark-transparent-gradient {
  background: linear-gradient(90deg, #fff 0%, #fff 45%, #ffffff00 100%);
}

#documental iframe {
  height: 100vh;
  width: 100vw;
}

.btn-pbo {
  color: #fafafa;
  background-color: #D90429;
  border-color: #B50000;
}

.btn-pbo:hover,
.btn-pbo:focus,
.btn-pbo:active,
.btn-pbo.active,
.open .dropdown-toggle.btn-pbo {
  color: #fafafa;
  background-color: #CC0000;
  border-color: #B50000;
}

.btn-pbo:active,
.btn-pbo.active,
.open .dropdown-toggle.btn-pbo {
  background-image: none;
}

.btn-pbo.disabled,
.btn-pbo[disabled],
fieldset[disabled] .btn-pbo,
.btn-pbo.disabled:hover,
.btn-pbo[disabled]:hover,
fieldset[disabled] .btn-pbo:hover,
.btn-pbo.disabled:focus,
.btn-pbo[disabled]:focus,
fieldset[disabled] .btn-pbo:focus,
.btn-pbo.disabled:active,
.btn-pbo[disabled]:active,
fieldset[disabled] .btn-pbo:active,
.btn-pbo.disabled.active,
.btn-pbo[disabled].active,
fieldset[disabled] .btn-pbo.active {
  background-color: #D90429;
  border-color: #B50000;
}

.btn-pbo .badge {
  color: #D90429;
  background-color: #fafafa;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.8s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate both;
  animation: scale-up-center 0.8s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}